import React, { useContext, useState, useEffect } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Context as BookingContext } from 'src/context/BookingContext'
import Separator from 'src/components/atoms/Separator'

const BOX = "border py-10 px-12 text-center rounded m-3 transition-all duration-200 cursor-pointer hover:border-grey-600";
const BOX_TITLE = "font-bold text-lg md:text-xl whitespace-no-wrap";
const BOX_PRICE = "text-2xl md:text-3xl text-grey-600 whitespace-no-wrap";
const ACTIVE = "shadow-md border-dark-blue-600";
const NOT_ACTIVE = "border-grey-500";

export default function SelectNrLessons({numberOfLessons, durationQuery, isTrial}) {
    const [ activeRadio, setActiveRadio ] = useState(0);
    const { state: { teacher, duration}, changeBooking } = useContext(BookingContext);
    const { t } = useTranslation();
    const { lesson_plans } = teacher;
    let priceList = lesson_plans.filter(({is_trial, lesson_duration_minutes}) => (!is_trial && lesson_duration_minutes === duration));
    let trial = (duration <= 30 && (parseInt(isTrial) === 1)) ? lesson_plans.filter(({is_trial}) => is_trial === true) : [];
    let list = [...trial, ...priceList];

    useEffect(() => {
        priceList = lesson_plans.filter(({is_trial, lesson_duration_minutes}) => (!is_trial && lesson_duration_minutes === duration));
        trial = (duration <= 30 && (parseInt(isTrial) === 1)) ? lesson_plans.filter(({is_trial}) => is_trial === true) : []
        list = (duration <= 30 && (parseInt(isTrial) === 1)) ? [...trial, ...priceList] : [...priceList];
        const plan = list.find((lessonPlan) => (lessonPlan.lesson_amount === parseInt(numberOfLessons)) && (lessonPlan.is_trial === (duration <= 30 && (parseInt(isTrial) === 1))));
        const planIndex = list.findIndex((lessonPlan) => (lessonPlan.lesson_amount === parseInt(numberOfLessons)) && (lessonPlan.is_trial === (duration <= 30 && (parseInt(isTrial) === 1))));
        if(plan && planIndex) {
            updateNrOfWantedLessons(plan, planIndex)
        }else if (plan) {
            changeBooking({
                nrOfLessons: plan.lesson_amount,
                lessonsToSchedule: [],
                price: plan,
                lessonPlan: plan.id,
                isTrial: plan.is_trial
            })
        }
    }, [numberOfLessons, durationQuery]);

    function updateNrOfWantedLessons(lesson, i) {
        setActiveRadio(i);
        changeBooking({
            nrOfLessons: lesson.lesson_amount,
            lessonsToSchedule: [],
            price: lesson,
            lessonPlan: lesson.id,
            isTrial: lesson.is_trial
        })
    }
    return (
        <>
            <div className="my-10">
                <p className="text-lg md:text-xl md:font-bold">
                    {t('bookATeacher.selectNrLessons')}
                </p>
                <div className="flex overflow-x-scroll py-4">
                    {list.map((priceCol, index) => (
                        <label key={index} className={classNames(BOX, activeRadio === index ? ACTIVE : NOT_ACTIVE)}>
                            <p className={classNames(BOX_TITLE)}>
                                {priceCol.is_trial && (
                                    <span>Trial</span>
                                )}
                                {!priceCol.is_trial && priceCol.lesson_amount}{' '}
                                {!priceCol.is_trial && priceCol.lesson_amount > 1 ? t('bookATeacher.lessons') : t('bookATeacher.lesson')}
                            </p>
                            <p className={classNames(BOX_PRICE)}>€ {priceCol.discounted_total_price_for_students.value}</p>
                            <input
                                type="radio"
                                onChange={() => updateNrOfWantedLessons(priceCol, index)}
                                checked={index === activeRadio}
                            />
                        </label>
                    ))}
                </div>
            </div>
            <Separator/>
        </>
    )
}
