import React, {useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {navigate} from 'gatsby'
import {Context as ThemeContext} from 'src/context/ThemeContext'
import {Context as BookingContext} from 'src/context/BookingContext'
import useLocalizedRoutes from 'src/hooks/useLocalizedRoutes'
import MarketingLayout from 'src/components/layout/MarketingLayout'
import {useVisitorApi} from 'src/hooks/useApi'
import BookATeacherBanner from 'src/components/sections/booking/BookATeacherBanner'
import AddInstrumentsDuration from 'src/components/sections/booking/AddInstrumentsDuration'
import FixedFooter from 'src/components/sections/teacherProfile/FixedFooter'
import SelectNrLessons from 'src/components/sections/booking/SelectNrLessons'
import ScheduleLessons from 'src/components/sections/booking/ScheduleLessons'
import AddComments from 'src/components/sections/booking/AddComments'
import useNoTeachersAllowed from 'src/hooks/useNoTeachersAllowed'
import Error from 'src/components/forms/Error'
import { useQueryParam, StringParam } from 'use-query-params'
import { DateTime } from 'luxon'

export default function BookATeacher(props) {
    const {t} = useTranslation();
    const {setTheme} = useContext(ThemeContext);
    const {state, changeBooking} = useContext(BookingContext);
    const {id} = props;
    const {bookingDetailsReview} = useLocalizedRoutes();
    const [loading, setLoading] = useState(false);
    const {getSingleTeacher} = useVisitorApi();
    const [numberOfLessons] = useQueryParam("numberOfLessons", StringParam);
    const [isTrial] = useQueryParam("isTrial", StringParam);
    const [durationQuery] = useQueryParam("duration", StringParam);
    const [error, setError] = useState(false);

    useEffect(() => setTheme('inverse'), [])
    useNoTeachersAllowed()

    useEffect(() => {
        if (!state.teacher || state.teacher.id !== id) {
            getTeacher()
        } else {
            changeBooking({
                teacherID: id,
                selectedDay: DateTime.utc().toISODate(),
            })
        }
    }, [id])

    function setDefaultBooking(teacher) {
        let instrument = teacher.instruments[0].id;
        let price = teacher.lesson_plans.find(({lesson_amount, is_trial, lesson_duration_minutes}) => (lesson_amount === 1 && !is_trial && lesson_duration_minutes === 30));
        changeBooking({
            ...state,
            teacherID: id,
            instrument,
            teacher: teacher,
            duration: 30,
            price,
            nrOfLessons: 1,
            selectedDay: DateTime.utc().toISO()
        })
    }

    async function getTeacher() {
        setLoading(true)
        let {data} = await getSingleTeacher(id)
        setLoading(false)
        setDefaultBooking(data.data)
    }

    function isValid() {
        return state.lessonsToSchedule.every(lesson => Object.values(lesson).every(key => key !== null))
    }

    function beginBooking() {
        if(isValid()) navigate(bookingDetailsReview);

        else setError(true)
    }

    function checkIfTeacher() {
        if (state.teacher) {
            return (
                <>
                    <BookATeacherBanner teacher={state.teacher}/>
                    <AddInstrumentsDuration durationQuery={durationQuery} numberOfLessons={numberOfLessons} isTrial={isTrial}/>
                    {state.duration &&
                        <SelectNrLessons numberOfLessons={numberOfLessons} durationQuery={durationQuery} isTrial={isTrial}/>
                    }
                    <ScheduleLessons />
                    { error && <Error>Select a date and time for each lesson</Error>}
                    <AddComments/>
                    <FixedFooter
                        onClick={beginBooking}
                    />
                </>
            )
        }

        return t('bookATeacher.noTeacherFound')
    }

    return (
        <MarketingLayout {...props} noFooter title={t('bookATeacher.title')}>
            <div className="container mx-auto px-6 lg:px-20">
                {checkIfTeacher()}
            </div>
        </MarketingLayout>
    )
}
